/* global $ */
import '../../css/app/components/home.scss';
class Home
{
    constructor () 
    {
        this.fixBannerHeight();
        this.scrollServiceBlocks();
        $(window).resize(() => {
            this.fixBannerHeight();
        });
    }
    fixBannerHeight() {
        if ($('.video-banner').length && $('.home-services-container').length && $(window).innerWidth() < 992) {
            $('.header-banner').height($(window).innerHeight());
            $('.video-banner').height($(window).innerHeight());
            $('.banner-height').height($(window).innerHeight());
        } else if ($('.video-banner').length && $('.home-services-container').length && $(window).innerWidth() > 991) {
            $('.header-banner').css('height', $(window).innerHeight() - 75);
            $('.video-banner').css('height', $(window).innerHeight() - 75);
            $('.banner-height').css('height', $(window).innerHeight() - 75);
        }
        if ($('.banner-embed').length && $(window).innerWidth() < 992) {
            let bannerW = $(window).innerWidth();
            let bannerH = $(window).innerHeight();
            if (bannerW / bannerH >= 16 / 9) {
                $('.banner-embed').width('100%').height($(window).innerWidth() * 9 / 16);
            } else {
                $('.banner-embed').height(bannerH).width(bannerH * 16 / 9);
            }
        } else if ($('.banner-embed').length && $(window).innerWidth() > 991) {
            let bannerW = $(window).innerWidth();
            let bannerH = $(window).innerHeight() - 75;
            if (bannerW / bannerH >= 16 / 9) {
                $('.banner-embed').width('100%').height($(window).innerWidth() * 9 / 16);
            } else {
                $('.banner-embed').height(bannerH).width(bannerH * 16 / 9);
            }
        }
    }

    scrollServiceBlocks() {
        if ($('.home-service-block').length) {
            $('.home-service-block').each(function() {
                let serviceHeading = $(this).find('.service-heading');
                serviceHeading.click(() => {
                    let scrollHeight = $(this).offset().top;
                    let blockHeight = $(this).height();
                    let scrollPos = scrollHeight + blockHeight + 10 - $(window).height();
                    $('html, body').animate({scrollTop: scrollPos}, 1000);
                });
            });
        }
    }    
}

new Home;